@charset "UTF-8";
@import "config";
@import "mixin";

.m-body--lp {
  padding-top: 50px;

}

.m-section {
	/* .m-section--inner */
	&--inner {
    width: $content_width;
    padding: 0 20px;
    margin: auto;
    box-sizing: border-box;
    @include sp {
      width: 100%;
      padding: 0 15px;
    }
	}
  /* .m-section--inner-spnoinner*/
  &--inner-spnoinner {
    @include sp {
      padding: 0;
    }
  }
}

.m-serif {
  font-family: $serif;
}

.m-title {
  font-size: 33px;
  text-align: center;
  font-family: $serif;
  @include sp {
    font-size: 24px;
  }
}

.m-page_title {
  padding: 70px 0;
  background-image: url(../images/m-bg-color.png);
  margin-bottom: 55px;
  @include sp {
    padding: 50px 0;
    margin-bottom: 25px;
  }
}

.m-headline {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 60px;
  line-height: 1.2;
  @include sp {
    font-size: 22px;
    margin-bottom: 40px;
  }
}

.m-sub_headline {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  @include sp {
    font-size: 16px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}

.m-btn {
  display: block;
  margin: auto;
  width: 240px;
  background-color: $primary_color;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 3px;
  &:hover {
    @include hover-saturate;
  }
  &:focus {
    box-shadow: none;
  }
  /* .m-btn-more */
  &-more {
    font-family: $serif;
    font-size: 17px;
    font-weight: bold;
    position: relative;
    margin-top: 50px;
    &:after {
      @include font-awesome("\f054");
      position: absolute;
      top: 50%;
      right: 10px;
      color: #fff;
      transform: translateY(-50%);
      font-size: 10px;
    }
  }
  /* .m-btn-prev */
  &-prev {
    position: relative;
    &:after {
      @include font-awesome("\f053");
      position: absolute;
      top: 50%;
      left: 15px;
      color: #fff;
      transform: translateY(-50%);
      font-size: 10px;
    }
  }
  /* .m-btn-next */
  &-next {
    position: relative;
    &:after {
      @include font-awesome("\f054");
      position: absolute;
      top: 50%;
      right: 15px;
      color: #fff;
      transform: translateY(-50%);
      font-size: 10px;
    }
  }
}

.m-pagination {
  /* .m-pagination--box */
  &--box {
    overflow: hidden;
  }
	/* .m-pagination--list */
	&--list {
    margin-top: 120px;
    display: flex;
    justify-content: center;
    @include sp {
      margin-top: 50px;
    }
	}
	/* .m-pagination--item */
	&--item {
    margin-right: 6px;
    &:last-of-type {
      margin-right: 0;
    }
    &.current {
      .m-pagination--link {
        background-color: $primary_color;
        color: #fff;
      }
    }
	}
	/* .m-pagination--link */
	&--link {
    display: block;
    width: 42px;
    line-height: 42px;
    border-radius: 50%;
    border: 1px solid $primary_color;
    text-align: center;
    background-color: #fff;
    &:not(.m-pagination--prev):not(.m-pagination--next) {
      &:hover {
        @include hover-bgcolor;
      }
    }
	}
	/* .m-pagination--next */
	&--next,
  &--prev {
    border: none;
    color: $primary_color;
    .fas {
      font-size: 16px;
    }
	}
}

.m-bread {
	/* .m-bread--section */
	&--section {
    background-color: $gray;
	}
	/* .m-bread--list */
	&--list {
    display: flex;
    line-height: 40px;
    @include sp {
      padding: 0 15px;
    }
	}
	/* .m-bread--item */
	&--item {
    padding-right: 20px;
    position: relative;
    &:after {
      content: "\03e";
      position: absolute;
      top: 0;
      right: 5px;
    }
    &:last-of-type {
      padding-right: 0;
      position: relative;
      flex: 1;
      overflow: hidden;
      &:after {
        content: none;
      }
    }
	}
	/* .m-bread--link */
  &--link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    &:not(span) {
      text-decoration: underline;
      &:hover {
        @include hover-text_link;
      }
    }
  }
}


.m-shopsearch {
	/* .m-shopsearch--form */
	&--form {

	}
  /* .m-shopsearch--form_box */
  &--form_box {
    @include clearfix;
  }
	/* .m-shopsearch--list */
	&--list {
	}
  /* .m-shopsearch--list-category */
  &--list-category {
    float: left;
    width: 384px;
    @include sp {
      float: none;
      width: 100%;
    }
  }
  /* .m-shopsearch--list-shopname */
  &--list-shopname {
    float: right;
    width: calc(100% - 390px - 64px);
    @include sp {
      float: none;
      width: 100%;
      margin-top: 44px;
    }
  }
  /* .m-shopsearch--list-shopsearch */
  &--list-shopsearch {
    float: right;
    width: calc(100% - 390px - 64px);
    margin-top: 28px;
    @include sp {
      float: none;
      width: 100%;
      margin-top: 44px;
    }
  }
	/* .m-shopsearch--term */
	&--term {
    font-size: 18px;
    font-weight: 500;
    &:before {
      content: "";
      display: inline-block;
      width: 22.5px;
      height: 22.5px;
      background-image: url(../images/m-shopsearch-list.svg);
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle;
    }
	}
	/* .m-shopsearch--description */
	&--description {
    margin-top: 20px;
	}
	/* .m-shopsearch--category_list */
	&--category_list {
    display: flex;
    flex-wrap: wrap;
	}
	/* .m-shopsearch--category_item */
	&--category_item {
    width: 120px;
    height: 130px;
    margin-top: 12px;
    margin-right: 12px;
    @include sp {
      width: calc((100vw - 30px - 24px) / 3);
      height: calc((100vw - 30px - 24px) * 2 / 5);
    }
    &:nth-of-type(-n+3) {
      margin-top: 0;
    }
    &:nth-of-type(3n) {
      margin-right: 0;
    }
	}
	/* .m-shopsearch--category_label */
	&--category_label {
    cursor: pointer;
	}
	/* .m-shopsearch--category_input */
	&--category_input {
    display: none;
    &:checked + .m-shopsearch--category_btn {
      background-color: #fffac4;
      border: 3px solid $primary_color;
      &:before {
        content: "";
        display: block;
        background-color: $primary_color;
        width: 26px;
        height: 26px;
        position: absolute;
        top: -3px;
        left: -3px;
        border-radius: 5px;
      }
      &:after {
        @include font-awesome("\f00c") {
          color: #fff;
          position: absolute;
          top: 3px;
          left: 3px;
        }
      }
    }
	}
  /* .m-shopsearch--category_btn */
  &--category_btn {
    color: #333;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: block;
    transition: .3s all;
    position: relative;
    background-repeat: no-repeat;
    border: 3px solid #fff;
    box-sizing: border-box;
    &:hover {
      border: 3px solid $primary_color;
      @include sp {
        border: 3px solid #fff;
      }
    }
    /* .m-shopsearch--category_btn-fashion */
    &-fashion {
      background-image: url(../images/m-shopsearch-category-btn-fashion.svg);
      background-position: 50% 25%;
    }
    /* .m-shopsearch--category_btn-goods */
    &-goods {
      background-image: url(../images/m-shopsearch-category-btn-goods.svg);
      background-position: 50% 35%;
    }
    /* .m-shopsearch--category_btn-restaurant */
    &-restaurant {
      background-image: url(../images/m-shopsearch-category-btn-restaurant.svg);
      background-position: 50% 25%;
    }
    /* .m-shopsearch--category_btn-gourmet */
    &-gourmet {
      background-image: url(../images/m-shopsearch-category-btn-gourmet.svg);
      background-position: 50% 35%;
    }
    /* .m-shopsearch--category_btn-beautiy */
    &-beautiy {
      background-image: url(../images/m-shopsearch-category-btn-beautiy.svg);
      background-position: 50% 22%;
    }
    /* .m-shopsearch--category_btn-service */
    &-service {
      background-image: url(../images/m-shopsearch-category-btn-service.svg);
      background-position: 50% 25%;
    }
  }
	/* .m-shopsearch--category_text */
	&--category_text {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    line-height: 1.5;
    font-weight: 500;
	}
  /* .m-shopsearch--shopname_select_box */
  &--shopname_select_box {
    position: relative;
    width: 335px;
    background-color: #fff;
    @include sp {
      width: 100%;
    }
    &:after {
      @include font-awesome("\f078");
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      color:#333;
    }
  }
	/* .m-shopsearch--shopname_select */
	&--shopname_select {
    height: 60px;
    padding: 0 22px;
    font-weight: 500;
    &:hover {
      box-shadow: 0 0 0 1px $primary_color;
    }
	}
	/* .m-shopsearch--shopname_option */
	&--shopname_option {
	}
  /* .m-shopsearch--input_box */
  &--input_box {
    position: relative;
    width: 502px;
    background-color: #fff;
    @include sp {
      width: 100%;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      background-image: url(../images/m-shopsearch-input-box.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 22.5px;
      height: 22.5px;
    }
  }
  /* .m-shopsearch--input */
  &--input {
    padding: 0 48px 0 22px;
    z-index: 99;
    position: relative;
    height: 60px;
    font-weight: 500;
    transition: .3s all;
    &:hover {
      box-shadow: 0 0 0 1px $primary_color;
    }
  }
	/* .m-shopsearch--keyword_list */
	&--keyword_list {
    display: flex;
    flex-wrap: wrap;
	}
	/* .m-shopsearch--keyword_item */
	&--keyword_item {
    margin: 7px 10px 7px 0;
	}
	/* .m-shopsearch--keyword_label */
	&--keyword_label {
    cursor: pointer;
	}
	/* .m-shopsearch--keyword_input */
	&--keyword_input {
    display: none;
    &:checked + .m-shopsearch--keyword_text {
      background-color: $primary_color;
      color:#fff;
      border: 1px solid $primary_color;
    }
	}
	/* .m-shopsearch--keyword_text */
	&--keyword_text {
    line-height: 36px;
    border-radius: 18px;
    background-color: #fff;
    color: #333;
    display: inline-block;
    padding: 0 15px;
    transition: .3s all;
    font-weight: 500;
    border: 1px solid #fff;
    @include sp {
      font-size: 13px;
    }
    &:hover {
      border: 1px solid $primary_color;
    }
	}
	/* .m-shopsearch--btn */
	&--btn {
    margin-top: 33px;
    width: 335px;
    padding: 25px 15px;
    font-size: 17px;
	}
}

.m-stairs {
  /* .m-stairs--headline */
  &--headline {
    margin-top: 55px;
    margin-bottom: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
    text-align: center;
  }
	/* .m-stairs--list_box */
	&--list_box {
    border-top: 1px solid #ccc;
    padding: 20px 0;
	}
	/* .m-stairs--list */
	&--list {
    display: flex;
    justify-content: center;
    @include sp {
      justify-content: space-between;
      flex-wrap: wrap;
    }
	}
	/* .m-stairs--item */
	&--item {
    margin-right: 6px;
    width: 68px;
    @include sp {
      width: calc((100% - 24px) / 5);
      margin-right: 0;
      margin-top: 8px;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &:nth-of-type(-n+5) {
      margin-top: 0;
    }
	}
	/* .m-stairs--link */
	&--link {
    font-family: $serif;
    font-size: 16px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
    line-height: 30px;
    &:hover {
      background-color: $primary_color;
      color: #fff;
      opacity: 1;
      @include sp {
        background-color: #fff;
        color: #000;
      }
    }
	}
}
.m-hero--slide_box {
  background-color: #F5EECA;

  .m-hero--slide_link {
    display: block;
    &:hover {
      @include hover-opacity;
    }
  }
  .m-hero--slide_img {
    height: 600px;
    object-fit: cover;
    @include sp {
      height: 100vw;
    }
  }

  .slick-slide {
    width: 600px;
    margin: 0 15px;
    @include sp {
      width: 100vw;
      margin: 0;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 3px;
    cursor: pointer;
  }
  .slick-dots li button {
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-size: 35px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    color: #f2f2f2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    color: $primary_color;
  }
  .slick-arrow {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: calc(50% - 30px);
    display: block;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background-color: transparent;
    transition: .3s all;
    z-index: 999;
    width: 60px;
    height: 60px;
    background-color: $brown;
    border-radius: 50%;
    box-shadow: $shadow;
    @include sp {
      width: 35px;
      height: 35px;
    }
    &:hover {
      @include hover-opacity;
    }
  }
  .slick-prev {
    left: calc(50% - 345px);
    @include sp {
      left: 5px;
    }
    &:after {
      @include font-awesome("\f053");
      color: #fff;
      font-size: 28px;
      @include sp {
        font-size: 16px;
      }
    }
  }
  .slick-next {
    right: calc(50% - 345px);
    @include sp {
      right: 5px;
    }
    &:after {
      @include font-awesome("\f054");
      color: #fff;
      font-size: 28px;
      @include sp {
        font-size: 16px;
      }
    }
  }
}



.m-news {
  /* .m-news--box */
  &--box {

    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
    }
  }
	/* .m-news--title */
	&--title {
    width: 130px;
    background-color: $primary_color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    @include sp {
      width: 100%;
      line-height: 35px;
    }
	}
	/* .m-news--title_text */
	&--title_text {
    font-size: 13px;
    color: #fff;
	}
	/* .m-news--list */
	&--list {
    width: calc(100% - 130px);
    background-color: $gray;
    padding: 15px;
    box-sizing: border-box;
    @include sp {
      width: 100%;
    }
	}
	/* .m-news--item */
	&--item {
    margin-top: 12px;
    &:first-of-type {
      margin-top: 0;
    }
	}
	/* .m-news--item_link */
	&--item_link {
    text-decoration: underline;
    position: relative;
    padding-left: 15px;
    line-height: 1.2;
    display: inline-block;
    @include sp {
      font-size: 13px;
    }
    &:hover {
      @include hover-text_link;
    }
    &:before {
      @include font-awesome("\f054");
      position: absolute;
      top: 50%;
      left: 0;
      color: $primary_color;
      transform: translateY(-50%);
      text-decoration: none !important;
    }
	}
}

.m-new--tag {
  article {
    &>a {
      &:before {
        content: "NEW";
        color: #fff;
        background-color: $primary_color;
        font-weight: bold;
        font-family: $serif;
        display: block;
        padding: 25px 20px 5px;
        width: 65px;
        position: absolute;
        top: -10px;
        left: -40px;
        transform: rotate(-45deg);
        text-align: center;
      }
    }
  }
}

.m-topics {
  /* .m-topics--article_list */
	&--article_list {
    width: 980px;
    margin: auto;
    @include sp {
      overflow: scroll;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      padding: 0 15px;
      box-sizing: border-box;
    }
    /* .m-topics--article_list-slider */
    &-slider {
      display: flex;
      justify-content: center;
      @include sp {
        justify-content: flex-start;
      }
      .slick-slide {
        width: 320px;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
      .slick-arrow {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 160px;
        display: block;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background-color: transparent;
        transition: .3s all;
        @include sp {
          display: none !important;
        }
        &:hover {
          @include hover-opacity;
        }
      }
      .slick-prev {
        left: -60px;
        &:after {
          @include font-awesome("\f053");
          color: #998675;
          font-size: 30px;
          padding: 10px;
        }
      }
      .slick-next {
        right: -60px;
        &:after {
          @include font-awesome("\f054");
          color: #998675;
          font-size: 30px;
          padding: 10px;
        }
      }
      .slick-disabled {
        display: none !important;
      }

    }
	}

  /* .m-topics--article_list-noslider */
  &--article_list-noslider {
    display: flex;
    flex-wrap: wrap;
    @include sp {
      padding: 0;
      display: block;
    }
    .m-topics--article_item {
      width: 320px;
      margin-top: 50px;
      @include sp {
        width: 100%;
        margin: 55px auto 0;
      }
      @include se {
        width: 100%;
        min-width: 0;
      }
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      &:nth-of-type(-n+3) {
        margin-top: 0;
        @include sp {
          margin-top: 55px;
        }
      }
      &:first-of-type {
        @include sp {
          margin-top: 0;
        }
      }
      &:last-of-type {
        @include sp {
          margin: 55px auto 0;
          &:after {
            content: none;
          }
        }
      }
    }
    .m-topics--article_imgarea {
      @include sp {
        width: 100%;
        height: calc(100vw - 30px);
      }
    }
  }

	/* .m-topics--article_item */
	&--article_item {
    width: 320px;
    margin-right: 10px;
    @include sp {
      min-width: 320px;
    }
    &:last-of-type {
      @include sp {
        margin-right: 0;
        position: relative;
      }
      &:after {
        @include sp {  //iPhone対策
          content: "";
          position: absolute;
          top: 0;
          right: -15px;
          display: block;
          width: 15px;
          height: 100%;
        }
      }
    }
	}
	/* .m-topics--article_box */
	&--article_box {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
	}
  /* .m-topics--article_coverlink */
  &--article_coverlink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 999;
    &:hover {
      &+.m-topics--article_link {
        .m-topics--article_img {
          @include hover-opacity;
        }
        .m-topics--article_title,
        .m-topics--article_description {
          @include hover-underline;
        }
      }
    }
  }
	/* .m-topics--article_link */
	&--article_link {
	}
  /* .m-topics--article_imgarea */
  &--article_imgarea {
    margin-bottom: 20px;
    width: 320px;
    height: 320px;
  }
	/* .m-topics--article_img */
	&--article_img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: .3s all;
	}
	/* .m-topics--article_headline */
	&--article_headline {
	}
	/* .m-topics--article_date */
	&--article_date {
    color: #999;
    font-size: 12px;
	}
	/* .m-topics--article_title */
	&--article_title {
    margin-top: 15px;
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 2px;
    /* .m-topics--article_title-open_close */
    &-open_close {
      padding-right: 5px;
      white-space: normal;
      padding-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-all;
      /*IE対策*/
      line-height: 1.5em;
      max-height: 4.5em;
    }
	}
	/* .m-topics--article_description */
	&--article_description {
    padding-right: 5px;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    /*IE対策*/
    line-height: 1.5em;
    max-height: 3em;
	}
	/* .m-topics--article_category */
	&--article_category {
    border-left: 1px solid #333;
    padding-left: 5px;
    margin-left: 5px;
    margin-bottom: 3px;
	}
  /* .m-topics--article_tag */
  &--article_tag {
    background-color: $primary_color;
    color: #fff;
    display: inline-block;
    min-width: 66px;
    padding: 2px 6px;
    font-size: 11px;
    margin-top: 8px;
    margin-right: 6px;
    box-sizing: border-box;
    text-align: center;
  }
  /* .m-topics--article_name */
  &--article_name {
    color: $primary_color;
    font-size: 13px;
    margin-top: 15px;
  }
  &--pdf_link {
    margin: 15px 0 0 0;
    display: inline-block;
    text-decoration: underline;
    line-height: 1.5;
    &:hover {
      @include hover-text_link;
    }
  }
}

.m-shopnews {
	/* .m-shopnews--article_list */
	&--article_list {
    display: flex;
    flex-wrap: wrap;
    @include sp {
      overflow: scroll;
      width: 100%;
      flex-wrap: nowrap;
      padding: 0 15px;
      box-sizing: border-box;
    }
	}
	/* .m-shopnews--article_item */
	&--article_item {
    width: 263px;
    margin-right: 16px;
    margin-top: 16px;
    @include sp {
      min-width: 260px;
      margin-top: 0;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
      @include sp {
        margin-right: 16px;
      }
    }
    &:nth-of-type(-n+4) {
      margin-top: 0;
    }
    &:last-of-type {
      @include sp {
        margin-right: 0;
        position: relative;
      }
      &:after {
        @include sp {  //iPhone対策
          content: "";
          position: absolute;
          top: 0;
          right: -15px;
          display: block;
          width: 15px;
          height: 100%;
        }
      }
    }
	}
	/* .m-shopnews--article_box */
	&--article_box {
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
	}
  /* .m-shopnews--article_coverlink */
  &--article_coverlink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 999;
    &:hover {
      &+.m-shopnews--article_link {
        .m-shopnews--article_img {
          @include hover-opacity;
        }
        .m-shopnews--article_title,
        .m-shopnews--article_description {
          @include hover-underline;
        }
      }
    }
  }
	/* .m-shopnews--article_link */
	&--article_link {
    padding: 10px 10px 20px;
    display: block;
	}
  /* .m-shopnews--article_imgarea */
  &--article_imgarea {
    width: 243px;
    height: 243px;
    margin-bottom: 20px;
  }
	/* .m-shopnews--article_img */
	&--article_img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s all;
    &-thumb {
      margin: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
	}
	/* .m-shopnews--article_text */
	&--article_text {
    padding: 0 5px;
    height: 126px;
    display: flex;
    flex-direction: column;
	}
	/* .m-shopnews--article_headline */
	&--article_headline {
    font-size: 12px;
	}
	/* .m-shopnews--article_date */
	&--article_date {
    color: #999;
	}
	/* .m-shopnews--article_title */
	&--article_title {
    font-size: 16px;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 2px;
    @include sp {
      font-size: 16px;
    }
	}
	/* .m-shopnews--article_description */
	&--article_description {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    word-break: break-all;
    height: 20px;
    line-height: 20px;
	}
  /* .m-shopnews--article_shopinfo */
  &--article_shopinfo {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    color: $primary_color;
    font-size: 13px;
    margin-top: auto;
  }
  /* .m-shopnews--article_shopname */
  &--article_shopname {
    width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
	/* .m-shopnews--article_floor */
	&--article_floor {
    width: 60px;
    text-align: right;
    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 14px;
      background-image: url(../images/icon-map-red.svg);
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle;
    }
	}
  /* .m-shopnews--article_note */
  &--article_note {
    margin-top: 10px;
  }
	/* .m-shopnews--list_box */
	&--list_box {
    background-color: $gray;
    padding: 48px 0 64px;
    overflow: hidden;
	}
	/* .m-shopnews--headline */
	&--headline {
    font-weight: normal;
    text-align: center;
    font-size: 16px;
	}
  /* .m-shopnews--slider_box */
  &--slider_box {
    margin-top: 40px;
    .m-shopnews--article_list {
      justify-content: center;
      @include sp {
        justify-content: flex-start;
      }
    }
    .slick-slide {
      width: 263px;
      margin-right: 16px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .slick-arrow {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 160px;
      display: block;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background-color: transparent;
      transition: .3s all;
      @include sp {
        display: none !important;
      }
      &:hover {
        @include hover-opacity;
      }
    }
    .slick-prev {
      left: -60px;
      &:after {
        @include font-awesome("\f053");
        color: #999;
        font-size: 30px;
        padding: 10px;
      }
    }
    .slick-next {
      right: -60px;
      &:after {
        @include font-awesome("\f054");
        color: #999;
        font-size: 30px;
        padding: 10px;
      }
    }
    .slick-disabled {
      display: none !important;
    }
  }
}

.m-pickup {
	/* .m-pickup--list */
	&--list {
    display: flex;
    justify-content: center;
    .slick-slide {
      width: 166px;
      margin: 0 5px;
      @include sp {
        width: calc((100vw - 30px) / 2 - 10px);
      }
    }
	}
	/* .m-pickup--link */
	&--link {
    display: block;
    &:hover {
      @include hover-opacity;
    }
	}
	/* .m-pickup--img */
	&--img {
    margin: 0 5px;
    object-fit: cover;
    width: 166px;
    height: 166px;
    @include sp {
      width: calc((100vw - 30px) / 2 - 10px);
      width: calc((100vw - 30px) / 2 - 10px);
    }
	}
}




.m-floorguide {
	/* .m-floorguide--list */
	&--list {
    width: 690px;
    margin: auto;
    @include sp {
      width: 100%;
    }
	}
	/* .m-floorguide--item */
	&--item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:first-of-type {
      .m-floorguide--floor_number {
        border-top: 1px solid #fff;
      }
    }
	}
	/* .m-floorguide--floor_number */
	&--floor_number {
    font-family: $serif;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #fff;
    width: 56px;
    text-align: center;
    padding: 2px 0;
    line-height: 60px;
    margin-right: 9px;
    @include sp {
      font-size: 14px;
      width: 30px;
      line-height: 42px;
      margin-right: 5px;
    }
	}
	/* .m-floorguide--floor_link */
	&--floor_link {
    display: block;
    width: 625px;
    background-color: #fff;
    box-shadow: $shadow;
    height: 60px;
    position: relative;
    @include sp {
      width: calc(100% - 30px - 5px);
      height: 42px;
    }
    &:after {
      @include font-awesome("\f054");
      position: absolute;
      top: 50%;
      right: 20px;
      color: #333;
      font-size: 12px;
      transform: translateY(-50%);
      @include sp {
        right: 10px;
        font-size: 8px;
      }
    }
    &:hover {
      @include hover-bgcolor;
      &:after {
        color: #fff;
        @include sp {
          color: #333;
        }
      }
    }
	}
	/* .m-floorguide--floor_name */
	&--floor_name {
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-feature-settings: "palt";
    @include sp {
      font-size: 12px;
      left: 10px;
    }
    @include se {
      font-size: 10px;
      left: 8px;
    }
	}
	/* .m-floorguide--floor_icon */
	&--floor_icon {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    @include sp {
      right: 25px;
    }
	}
	/* .m-floorguide--floor_img */
	&--floor_img {
    @include sp {
      width: 17px;
    }
	}
	/* .m-floorguide--floor_link-l */
	&--floor_link-l {
    width: 423px;
    margin-right: 4px;
    @include sp {
      width: calc(100% - 30px - 5px - 120px - 4px);
    }
	}
	/* .m-floorguide--floor_link-s */
	&--floor_link-s {
    width: calc(100% - 56px - 9px - 423px - 4px);
    @include sp {
      width: 120px;
    }
	}
}

.m-snsshare {
	/* .m-snsshare--headline */
	&--headline {
    font-weight: bold;
    text-align: center;
    &:before {
      content: "";
      width: 2px;
      height: 15px;
      background-color: #333;
      display: inline-block;
      transform: rotate(-25deg);
      margin-right: 10px;
    }
    &:after {
      content: "";
      width: 2px;
      height: 15px;
      background-color: #333;
      display: inline-block;
      transform: rotate(25deg);
      margin-left: 10px;
    }
	}
	/* .m-snsshare--list */
	&--list {
    display: flex;
    justify-content: center;
    margin-top: 18px;
	}
	/* .m-snsshare--item */
	&--item {
    margin-right: 18px;
    &:last-of-type {
      margin-right: 0;
    }
	}
}
.m-snsshare-link {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $primary_color;
  position: relative;
  &:hover {
    @include hover-saturate;
  }
}
.m-snsshare-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.m-snsshare-img-facebook {
  width: 9px;
}
.m-snsshare-img-line {
  width: 18px;
}
.m-snsshare-img-twitter {
  width: 15px;
}

.m-map {
  /* .m-map--box */
  &--box {
    position: relative;
  }
	/* .m-map--wrapper */
	&--wrapper {
    @include sp {
      overflow: hidden;
      overflow-x: scroll;
    }
	}
  /* .m-map--slider */
  &--slider {
    margin: auto;
    @include sp {
      padding: 0 15px;
    }
  }
  /* .m-map--wrapper_icon */
  &--wrapper_icon {
    display: none;
    @include sp {
      display: block;
      position: absolute;
      bottom: -40px;
      right: 15px;
    }
  }
	/* .m-map--slider-b1f */
	&--slider-b1f {
    width: 732px;
    @include sp {
      width: 512.4px;
    }
	}
	/* .m-map--slider-1f */
	&--slider-1f {
    width: 809px;
    @include sp {
      width: 566.3px;
    }
	}
	/* .m-map--slider-2f */
	&--slider-2f {
    width: 798px;
    @include sp {
      width: 558.6px;
    }
	}
	/* .m-map--slider-3f */
	&--slider-3f {
    width: 810px;
    @include sp {
      width: 567px;
    }
	}
	/* .m-map--slider-4f */
	&--slider-4f {
    width: 810px;
    @include sp {
      width: 567px;
    }
	}
	/* .m-map--slider-5f */
	&--slider-5f {
    width: 810px;
    @include sp {
      width: 567px;
    }
	}
	/* .m-map--slider-6f */
	&--slider-6f {
    width: 810px;
    @include sp {
      width: 567px;
    }
	}
	/* .m-map--slider-7f */
	&--slider-7f {
    width: 810px;
    @include sp {
      width: 567px;
    }
	}
	/* .m-map--slider-shokusaikan */
	&--slider-shokusaikan {
    width: 798px;
    //width: calc(1595px / 2);
    @include sp {
      width: 558px;
    }
	}
	/* .m-map--img_box */
	&--img_box {
    position: relative;
    svg {
      padding-right: 15px;
      @include sp {
        width: 100%;
        height: auto;
      }
      .close {
        fill: #ede6e0;
        rect,polygon,path {
          fill: #ede6e0 !important;
        }
      }
    }
	}
	/* .m-map--pin */
	&--pin {
    position: absolute;
    &.is_detail_page {
      .m-map {
        &--pin_img {
          display: none;
        }
      }
    }
	}
  /* .m-map--pin_text */
  &--pin_text {
    font-size: 12px;
    font-weight: bold;
    display: block;
    width: 32px;
    line-height: 32px;
    text-align: center;
    @include sp {
      font-size: 10px;
      width: 22px;
      line-height: 22px;
    }
    &:hover {
      @include hover-text_link;
    }
  }
  /* .m-map--pin_img */
  &--pin_img {
    display: block;
    margin: auto;
    transform: translateY(-10px);
    @include sp {
      width: 18px;
      transform: translateY(-8px);
    }
  }
	/* .m-map--pin-b1ftenant_b1-1 */
	&--pin-b1ftenant_b1-1 {
    bottom: 48%;
    left: 17%;
	}
	/* .m-map--pin-b1ftenant_b1-2 */
	&--pin-b1ftenant_b1-2 {
    bottom: 63%;
    left: 49%;
	}
	/* .m-map--pin-b1ftenant_b1-3 */
	&--pin-b1ftenant_b1-3 {
    bottom: 70%;
    left: 70.5%;
	}
	// /* .m-map--pin-b1ftenant_b1-4 */
	// &--pin-b1ftenant_b1-4 {
  //   bottom: 70%;
  //   left: 74.5%;
	// }
	/* .m-map--pin-b1ftenant_b1-5 */
	&--pin-b1ftenant_b1-4 {
    bottom: 10%;
    left: 67%;
	}
	/* .m-map--pin-b1ftenant_b1-6 */
	&--pin-b1ftenant_b1-5 {
    bottom: 10%;
    left: 51%;
	}
	/* .m-map--pin-b1ftenant_b1-7 */
	&--pin-b1ftenant_b1-6 {
    bottom: 10%;
    left: 31.5%;
	}
	/* .m-map--pin-b1ftenant_b1-8 */
	&--pin-b1ftenant_b1-7 {
    bottom: 10%;
    left: 14.5%;
	}
	/* .m-map--pin-b1ftenant_b1-9 */
	&--pin-b1ftenant_b1-8 {
    bottom: 5%;
    left: 9.5%;
	}
	/* .m-map--pin-b1ftenant_b1-10 */
	&--pin-b1ftenant_b1-9 {
    bottom: 5%;
    left: 5%;
	}
	/* .m-map--pin-b1ftenant_b1-11 */
	&--pin-b1ftenant_b1-10 {
    bottom: 39%;
    left: 46.5%;
	}
	&--pin-b1ftenant_b1-11 {
    bottom: 39%;
    left: 46.5%;
    display: none !important;
	}

  /* .m-map--pin-1ftenant1 */
	&--pin-1ftenant_1f-1 {
    bottom: 64%;
    left: 28.5%;
	}
	/* .m-map--pin-1ftenant_1f-2 */
	&--pin-1ftenant_1f-2 {
    bottom: 34%;
    left: 31.5%;
	}
	/* .m-map--pin-1ftenant_1f-3 */
	&--pin-1ftenant_1f-3 {
    bottom: 11%;
    left: 30%;
	}
	/* .m-map--pin-1ftenant_1f-4 */
	&--pin-1ftenant_1f-4 {
    bottom: 11%;
    left: 15.5%;
	}
	/* .m-map--pin-1ftenant_1f-5 */
	&--pin-1ftenant_1f-5 {
    bottom: 56%;
    left: 1.5%;
	}
	/* .m-map--pin-1ftenant_1f-6 */
	&--pin-1ftenant_1f-6 {
    bottom: 75%;
    left: 14%;
	}
	/* .m-map--pin-1ftenant_1f-7 */
	&--pin-1ftenant_1f-7 {
    bottom: 64%;
    left: 68%;
	}
	/* .m-map--pin-1ftenant_1f-8 */
	&--pin-1ftenant_1f-8 {
    bottom: 73%;
    left: 85%;
	}
	/* .m-map--pin-1ftenant_1f-9 */
	&--pin-1ftenant_1f-9 {
    bottom: 55%;
    left: 85.5%;
	}
	/* .m-map--pin-1ftenant_1f-10 */
	&--pin-1ftenant_1f-10 {
    bottom: 40%;
    left: 84%;
	}
	/* .m-map--pin-1ftenant_1f-11 */
	&--pin-1ftenant_1f-11 {
    bottom: 36%;
    left: 90%;
	}
	/* .m-map--pin-1ftenant_1f-12 */
	&--pin-1ftenant_1f-12 {
    bottom: 10%;
    left: 83%;
	}
	/* .m-map--pin-1ftenant_1f-13 */
	&--pin-1ftenant_1f-13 {
    bottom: 10%;
    left: 75%;
	}
	/* .m-map--pin-1ftenant_1f-14 */
	&--pin-1ftenant_1f-14 {
    bottom: 15%;
    left: 70%;
	}
	/* .m-map--pin-1ftenant_1f-15 */
	&--pin-1ftenant_1f-15 {
    bottom: 10%;
    left: 64%;
	}
	/* .m-map--pin-1ftenant_1f-16 */
	&--pin-1ftenant_1f-16 {
    bottom: 38%;
    left: 62.5%;
	}
	/* .m-map--pin-1ftenant_1f-17 */
	&--pin-1ftenant_1f-17 {
    bottom: 37%;
    left: 72%;
	}
	/* .m-map--pin-1ftenant_1f-18 */
	&--pin-1ftenant_1f-18 {
    display: none;
	}
  /* .m-map--pin-2ftenant_2f-1 */
	&--pin-2ftenant_2f-1 {
    bottom: 71%;
    left: 22%;
	}
	/* .m-map--pin-2ftenant_2f-2 */
	&--pin-2ftenant_2f-2 {
    bottom: 77%;
    left: 36%;
	}
	/* .m-map--pin-2ftenant_2f-3 */
	&--pin-2ftenant_2f-3 {
    bottom: 77%;
    left: 46.5%;
	}
	/* .m-map--pin-2ftenant_2f-4 */
	&--pin-2ftenant_2f-4 {
    bottom: 77%;
    left: 54%;
	}
	/* .m-map--pin-2ftenant_2f-5 */
	&--pin-2ftenant_2f-5 {
    bottom: 70%;
    left: 64%;
	}
	/* .m-map--pin-2ftenant_2f-6 */
	&--pin-2ftenant_2f-6 {
    bottom: 77%;
    left: 74%;
	}
	/* .m-map--pin-2ftenant_2f-7 */
	&--pin-2ftenant_2f-7 {
    bottom: 77%;
    left: 83.5%;
	}
	/* .m-map--pin-2ftenant_2f-8 */
	&--pin-2ftenant_2f-8 {
    bottom: 21%;
    left: 91%;
	}
	/* .m-map--pin-2ftenant_2f-9 */
	&--pin-2ftenant_2f-9 {
    bottom: 18%;
    left: 82%;
	}
	/* .m-map--pin-2ftenant_2f-10 */
	&--pin-2ftenant_2f-10 {
    bottom: 28%;
    left: 71%;
	}
	/* .m-map--pin-2ftenant_2f-11 */
	&--pin-2ftenant_2f-11 {
    bottom: 26%;
    left: 55.5%;
	}
	/* .m-map--pin-2ftenant_2f-12 */
	&--pin-2ftenant_2f-12 {
    bottom: 26%;
    left: 45%;
	}
	/* .m-map--pin-2ftenant_2f-13 */
	&--pin-2ftenant_2f-13 {
    bottom: 26%;
    left: 37%;
	}
	/* .m-map--pin-2ftenant_2f-14 */
	&--pin-2ftenant_2f-14 {
    bottom: 26%;
    left: 28%;
	}
	/* .m-map--pin-2ftenant_2f-15 */
	&--pin-2ftenant_2f-15 {
    bottom: 26%;
    left: 19%;
	}
	/* .m-map--pin-2ftenant_2f-16 */
	&--pin-2ftenant_2f-16 {
    bottom: 47%;
    left: 12%;
	}
	/* .m-map--pin-2ftenant_2f-17 */
	&--pin-2ftenant_2f-17 {
    bottom: 49%;
    left: 37%;
	}
	/* .m-map--pin-2ftenant_2f-18 */
	&--pin-2ftenant_2f-18 {
    bottom: 49%;
    left: 51.5%;
	}
	/* .m-map--pin-2ftenant_2f-19 */
	&--pin-2ftenant_2f-19 {
    bottom: 49%;
    left: 60%;
	}
	/* .m-map--pin-2ftenant_2f-20 */
	&--pin-2ftenant_2f-20 {
    bottom: 43%;
    left: 72%;
	}
	/* .m-map--pin-2ftenant_2f-21 */
	&--pin-2ftenant_2f-21 {
    bottom: 49%;
    left: 84%;
	}
	/* .m-map--pin-3ftenant_3f-1 */
	&--pin-3ftenant_3f-1 {
    bottom: 48%;
    left: 11.5%;
	}
	/* .m-map--pin-3ftenant_3f-2 */
	&--pin-3ftenant_3f-2 {
    bottom: 36%;
    left: 59.5%;
	}
	/* .m-map--pin-3ftenant_3f-3 */
	&--pin-3ftenant_3f-3 {
    bottom: 9%;
    left: 31.5%;
	}
	/* .m-map--pin-3ftenant_3f-4 */
	&--pin-3ftenant_3f-4 {
    bottom: 9%;
    left: 21.5%;
	}
	/* .m-map--pin-3ftenant_3f-5 */
	&--pin-3ftenant_3f-5 {
    bottom: 64%;
    left: 33.5%;
	}
	/* .m-map--pin-4ftenant_4f-1 */
	&--pin-4ftenant_4f-1 {
    bottom: 67%;
    left: 12.5%;
	}
	/* .m-map--pin-4ftenant_4f-2 */
	&--pin-4ftenant_4f-2 {
    bottom: 70%;
    left: 37.5%;
	}
	/* .m-map--pin-4ftenant_4f-3 */
	&--pin-4ftenant_4f-3 {
    bottom: 36%;
    left: 60.5%;
	}
	/* .m-map--pin-4ftenant_4f-4 */
	&--pin-4ftenant_4f-4 {
    bottom: 8%;
    left: 31.5%;
	}
	/* .m-map--pin-4ftenant_4f-5 */
	&--pin-4ftenant_4f-5 {
    bottom: 35%;
    left: 12%;
	}
	/* .m-map--pin-4ftenant_4f-6 */
	&--pin-4ftenant_4f-6 {
        bottom: 8%;
        left: 18.5%;
	}

	/* .m-map--pin-5ftenant_5f-1 */
	&--pin-5ftenant_5f-1 {
    bottom: 60%;
    left: 12.5%;
	}
	/* .m-map--pin-5ftenant_5f-2 */
	&--pin-5ftenant_5f-2 {
    bottom: 68%;
    left: 40%;
	}
	/* .m-map--pin-5ftenant_5f-3 */
	&--pin-5ftenant_5f-3 {
    bottom: 59%;
    left: 49%;
	}
	/* .m-map--pin-5ftenant_5f-4 */
	&--pin-5ftenant_5f-4 {
    bottom: 69%;
    left: 68.5%;
	}
	/* .m-map--pin-5ftenant_5f-5 */
	&--pin-5ftenant_5f-5 {
    bottom: 14%;
    left: 82%;
	}
	/* .m-map--pin-5ftenant_5f-6 */
	&--pin-5ftenant_5f-6 {
    bottom: 14%;
    left: 66.5%;
	}
	/* .m-map--pin-5ftenant_5f-7 */
	&--pin-5ftenant_5f-7 {
    bottom: 17%;
    left: 42.5%;
	}
	/* .m-map--pin-5ftenant_5f-8 */
	&--pin-5ftenant_5f-8 {
    bottom: 16%;
    left: 14.5%;
	}
	/* .m-map--pin-6ftenant_6f-1 */
	&--pin-6ftenant_6f-1 {
    bottom: 66%;
    left: 41%;
	}
	/* .m-map--pin-6ftenant_6f-2 */
	&--pin-6ftenant_6f-2 {
    bottom: 66%;
    left: 53%;
	}
	/* .m-map--pin-6ftenant_6f-3 */
	&--pin-6ftenant_6f-3 {
    bottom: 60%;
    left: 64%;
	}
	/* .m-map--pin-6ftenant_6f-4 */
	&--pin-6ftenant_6f-4 {
    bottom: 66%;
    left: 78%;
	}
	/* .m-map--pin-6ftenant_6f-5 */
	&--pin-6ftenant_6f-5 {
    bottom: 9%;
    left: 81%;
	}
	/* .m-map--pin-6ftenant_6f-6 */
	&--pin-6ftenant_6f-6 {
    bottom: 9%;
    left: 67.5%;
	}
	/* .m-map--pin-6ftenant_6f-7 */
	&--pin-6ftenant_6f-7 {
    bottom: 16%;
    left: 57%;
	}
	/* .m-map--pin-6ftenant_6f-8 */
	&--pin-6ftenant_6f-8 {
    bottom: 16%;
    left: 45%;
	}
	/* .m-map--pin-6ftenant_6f-9 */
	&--pin-6ftenant_6f-9 {
    bottom: 16%;
    left: 37.5%;
	}
	/* .m-map--pin-6ftenant_6f-10 */
	&--pin-6ftenant_6f-10 {
    bottom: 5%;
    left: 28.5%;
	}
	/* .m-map--pin-6ftenant_6f-11 */
	&--pin-6ftenant_6f-11 {
    bottom: 5%;
    left: 22%;
	}
	/* .m-map--pin-6ftenant_6f-12 */
	&--pin-6ftenant_6f-12 {
    bottom: 16%;
    left: 14%;
	}
	/* .m-map--pin-6ftenant_6f-13 */
	&--pin-6ftenant_6f-13 {
    bottom: 73%;
    left: 6.5%;
	}
	/* .m-map--pin-6ftenant_6f-14 */
	&--pin-6ftenant_6f-14 {
    bottom: 60%;
    left: 12.5%;
	}
	/* .m-map--pin-6ftenant_6f-15 */
	&--pin-6ftenant_6f-15 {
    bottom: 58%;
    left: 27%;
	}
	/* .m-map--pin-6ftenant_6f-16 */
	&--pin-6ftenant_6f-16 {
    bottom: 16%;
    left: 51%;
	}
	/* .m-map--pin-7ftenant_7f-1 */
	&--pin-7ftenant_7f-1 {
    bottom: 69%;
    left: 10.5%;
	}
	/* .m-map--pin-7ftenant_7f-2 */
	&--pin-7ftenant_7f-2 {
    bottom: 69%;
    left: 40.5%;
	}
	/* .m-map--pin-7ftenant_7f-3 */
	&--pin-7ftenant_7f-3 {
    bottom: 20%;
    left: 26.5%;
	}
	/* .m-map--pin-7ftenant_7f-4 */
	&--pin-7ftenant_7f-4 {
    bottom: 36%;
    left: 68%;
	}
	/* .m-map--pin-7ftenant_7f-5 */
	&--pin-7ftenant_7f-5 {
    bottom: 69%;
    left: 35%;
	}
	/* .m-map--pin-7ftenant_7f-6 */
	// &--pin-7ftenant_7f-6 {
  //   bottom: 10%;
  //   left: 80%;
	// }

	/* .m-map--pin-syokusaikantenant_ss-1 */
	&--pin-syokusaikantenant_ss-1 {
    bottom: 91%;
    left: 29%;
	}
	/* .m-map--pin-syokusaikantenant_ss-2 */
	&--pin-syokusaikantenant_ss-2 {
    bottom: 89%;
    left: 45.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-3 */
	&--pin-syokusaikantenant_ss-3 {
    bottom: 79%;
    left: 40.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-4 */
	&--pin-syokusaikantenant_ss-4 {
    bottom: 79%;
    left: 49%;
	}
	/* .m-map--pin-syokusaikantenant_ss-5 */
	&--pin-syokusaikantenant_ss-5 {
    bottom: 79%;
    left: 58.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-6 */
	&--pin-syokusaikantenant_ss-6 {
    bottom: 87%;
    left: 63.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-7 */
	&--pin-syokusaikantenant_ss-7 {
    bottom: 90%;
    left: 88%;
	}
	/* .m-map--pin-syokusaikantenant_ss-8 */
	&--pin-syokusaikantenant_ss-8 {
    bottom: 80%;
    left: 84.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-9 */
	&--pin-syokusaikantenant_ss-9 {
    bottom: 71%;
    left: 80%;
	}
	/* .m-map--pin-syokusaikantenant_ss-10 */
	&--pin-syokusaikantenant_ss-10 {
    bottom: 48%;
    left: 88.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-11 */
	&--pin-syokusaikantenant_ss-11 {
    bottom: 35%;
    left: 81%;
	}
	/* .m-map--pin-syokusaikantenant_ss-12 */
	&--pin-syokusaikantenant_ss-12 {
    bottom: 27.5%;
    left: 81%;
	}
	/* .m-map--pin-syokusaikantenant_ss-13 */
	&--pin-syokusaikantenant_ss-13 {
    bottom: 27.5%;
    left: 88%;
	}
	/* .m-map--pin-syokusaikantenant_ss-14 */
	&--pin-syokusaikantenant_ss-14 {
    bottom: 19%;
    left: 77%;
	}
	/* .m-map--pin-syokusaikantenant_ss-15 */
	&--pin-syokusaikantenant_ss-15 {
    bottom: 16%;
    left: 91.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-16 */
	&--pin-syokusaikantenant_ss-16 {
    bottom: 12.5%;
    left: 81%;
	}
	/* .m-map--pin-syokusaikantenant_ss-17 */
	&--pin-syokusaikantenant_ss-17 {
    bottom: 5%;
    left: 85%;
	}
	/* .m-map--pin-syokusaikantenant_ss-18 */
	&--pin-syokusaikantenant_ss-18 {
    bottom: 36%;
    left: 65.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-19 */
	&--pin-syokusaikantenant_ss-19 {
    bottom: 36%;
    left: 58.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-20 */
	&--pin-syokusaikantenant_ss-20 {
    bottom: 36%;
    left: 51.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-21 */
	&--pin-syokusaikantenant_ss-21 {
    bottom: 36%;
    left: 44.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-22 */
	&--pin-syokusaikantenant_ss-22 {
    bottom: 36%;
    left: 36.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-23 */
	&--pin-syokusaikantenant_ss-23 {
    bottom: 36%;
    left: 30.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-24 */
	&--pin-syokusaikantenant_ss-24 {
    bottom: 36%;
    left: 24%;
	}
	/* .m-map--pin-syokusaikantenant_ss-25 */
	&--pin-syokusaikantenant_ss-25 {
    bottom: 43.5%;
    left: 9%;
	}
	/* .m-map--pin-syokusaikantenant_ss-26 */
	&--pin-syokusaikantenant_ss-26 {
    bottom: 43.5%;
    left: 15%;
	}
	/* .m-map--pin-syokusaikantenant_ss-27 */
	&--pin-syokusaikantenant_ss-27 {
    bottom: 48%;
    left: 23.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-28 */
	&--pin-syokusaikantenant_ss-28 {
    bottom: 55%;
    left: 23.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-29 */
	&--pin-syokusaikantenant_ss-29 {
    bottom: 50%;
    left: 15.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-30 */
	&--pin-syokusaikantenant_ss-30 {
    bottom: 60%;
    left: 15.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-31 */
	&--pin-syokusaikantenant_ss-31 {
    bottom: 69%;
    left: 17.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-32 */
	&--pin-syokusaikantenant_ss-32 {
    bottom: 65%;
    left: 22.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-33 */
	&--pin-syokusaikantenant_ss-33 {
    bottom: 65%;
    left: 25%;
	}
	/* .m-map--pin-syokusaikantenant_ss-34 */
	&--pin-syokusaikantenant_ss-34 {
    bottom: 72%;
    left: 22.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-35 */
	&--pin-syokusaikantenant_ss-35 {
    bottom: 72%;
    left: 25%;
	}
	/* .m-map--pin-syokusaikantenant_ss-36 */
	&--pin-syokusaikantenant_ss-36 {
    bottom: 79.5%;
    left: 30.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-37 */
	&--pin-syokusaikantenant_ss-37 {
    bottom: 68%;
    left: 35.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-38 */
	&--pin-syokusaikantenant_ss-38 {
    bottom: 68%;
    left: 44%;
	}
	/* .m-map--pin-syokusaikantenant_ss-39 */
	&--pin-syokusaikantenant_ss-39 {
    bottom: 69.5%;
    left: 52.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-40 */
	&--pin-syokusaikantenant_ss-40 {
    bottom: 68%;
    left: 61.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-41 */
	&--pin-syokusaikantenant_ss-41 {
    bottom: 68%;
    left: 66.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-42 */
	&--pin-syokusaikantenant_ss-42 {
    bottom: 68%;
    left: 71%;
	}
	/* .m-map--pin-syokusaikantenant_ss-43 */
	&--pin-syokusaikantenant_ss-43 {
    bottom: 54%;
    left: 69%;
	}
	/* .m-map--pin-syokusaikantenant_ss-44 */
	&--pin-syokusaikantenant_ss-44 {
    bottom: 45%;
    left: 66%;
	}
	/* .m-map--pin-syokusaikantenant_ss-45 */
	&--pin-syokusaikantenant_ss-45 {
    bottom: 47.5%;
    left: 62%;
	}
	/* .m-map--pin-syokusaikantenant_ss-46 */
	&--pin-syokusaikantenant_ss-46 {
    bottom: 57%;
    left: 62%;
	}
	/* .m-map--pin-syokusaikantenant_ss-47 */
	&--pin-syokusaikantenant_ss-47 {
    bottom: 45%;
    left: 54.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-48 */
	&--pin-syokusaikantenant_ss-48 {
    bottom: 45%;
    left: 50.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-49 */
	&--pin-syokusaikantenant_ss-49 {
    bottom: 48%;
    left: 43%;
	}
	/* .m-map--pin-syokusaikantenant_ss-50 */
	&--pin-syokusaikantenant_ss-50 {
    bottom: 45%;
    left: 36.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-51 */
	&--pin-syokusaikantenant_ss-51 {
    bottom: 53%;
    left: 31.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-52 */
	&--pin-syokusaikantenant_ss-52 {
    bottom: 60%;
    left: 38%;
	}
	/* .m-map--pin-syokusaikantenant_ss-53 */
	&--pin-syokusaikantenant_ss-53 {
    bottom: 78%;
    left: 8.5%;
	}
	/* .m-map--pin-syokusaikantenant_ss-54 */
	&--pin-syokusaikantenant_ss-54 {
    // bottom: 63.5%;
    // left: 80.3%;
	}
	/* .m-map--pin-syokusaikantenant_ss-55 */
	&--pin-syokusaikantenant_ss-55 {
    /*bottom: 78%;
    left: 8.5%;*/
	}
  &--picto_box-noclick {
    .m-map--picto_link {
      pointer-events: none;
    }
    .m-map--picto_description {
      display: block;
    }
  }
	/* .m-map--picto_list */
	&--picto_list {
    color: #808080;
	}
	/* .m-map--picto_term */
	&--picto_term {
    font-size: 13px;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
	}
  /* .m-map--picto_link */
  &--picto_link {
    display: block;
    padding-bottom: 8px;
    width: 120px;
    margin: auto;
    &:hover {
      @include hover-opacity;
    }
    &:after {
      @include font-awesome("\f078");
      display: block;
      margin: 10px auto 0;
      font-size: 12px;
    }
  }
	/* .m-map--picto_description */
	&--picto_description {
    display: none;
    border-bottom: 1px solid #e6e6e6;
    padding: 15px 15px 25px;
    font-size: 12px;
    @include sp {
      padding-left: 0;
      padding-right: 0;
    }
    @include se {
      font-size: 10px;
    }
	}
	/* .m-map--pictoicon_list */
	&--pictoicon_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
	}
	/* .m-map--pictoicon_item */
	&--pictoicon_item {
    width: 10%;
    font-feature-settings: "palt";
    line-height: 30px;
    @include sp {
      width: 33%;
    }
    &:first-of-type {
      width: 100%;
    }
	}
	/* .m-map--pictoicon_text */
	&--pictoicon_text {
    position: relative;
    padding-left: 20px;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../images/floor/icon-info.svg);
    }
	}
	/* .m-map--pictoicon_text-info */
	&--pictoicon_text-info {
    &:before {
      background-image: url(../images/floor/icon-info.svg);
    }
	}
	/* .m-map--pictoicon_text-wtoilet */
	&--pictoicon_text-wtoilet {
    &:before {
      background-image: url(../images/floor/icon-wtoilet.svg);
    }
	}
	/* .m-map--pictoicon_text-mtoilet */
	&--pictoicon_text-mtoilet {
    &:before {
      background-image: url(../images/floor/icon-mtoilet.svg);
    }
	}
	/* .m-map--pictoicon_text-powder */
	&--pictoicon_text-powder {
    &:before {
      background-image: url(../images/floor/icon-powder.svg);
    }
	}
	/* .m-map--pictoicon_text-wheelchair */
	&--pictoicon_text-wheelchair {
    &:before {
      background-image: url(../images/floor/icon-wheelchair.svg);
    }
	}
	/* .m-map--pictoicon_text-babyroom */
	&--pictoicon_text-babyroom {
    &:before {
      background-image: url(../images/floor/icon-babyroom.svg);
    }
	}
	/* .m-map--pictoicon_text-ktoilet */
	&--pictoicon_text-ktoilet {
    &:before {
      background-image: url(../images/floor/icon-ktoilet.svg);
    }
	}
	/* .m-map--pictoicon_text-babycar */
	&--pictoicon_text-babycar {
    &:before {
      background-image: url(../images/floor/icon-babycar.svg);
    }
	}
	/* .m-map--pictoicon_text-restaurant */
	&--pictoicon_text-restaurant {
    &:before {
      background-image: url(../images/floor/icon-restaurant.svg);
    }
	}
	/* .m-map--pictoicon_text-cafe */
	&--pictoicon_text-cafe {
    &:before {
      background-image: url(../images/floor/icon-cafe.svg);
    }
	}
  /* .m-map--pictoicon_text-shopping */
  &--pictoicon_text-shopping {
    &:before {
      background-image: url(../images/floor/icon-shoping.svg);
    }
  }
  /* .m-map--pictoicon_text-aed */
  &--pictoicon_text-aed {
    &:before {
      background-image: url(../images/floor/icon-aed.svg);
    }

  }
	/* .m-map--pictoicon_text-atm */
	&--pictoicon_text-atm {
    &:before {
      background-image: url(../images/floor/icon-atm.svg);
    }
	}
	/* .m-map--pictoicon_text-escalator */
	&--pictoicon_text-escalator {
    &:before {
      background-image: url(../images/floor/icon-escalator.svg);
    }
	}
	/* .m-map--pictoicon_text-elevator */
	&--pictoicon_text-elevator {
    &:before {
      background-image: url(../images/floor/icon-elevator.svg);
    }
	}
	/* .m-map--pictoicon_text-stairs */
	&--pictoicon_text-stairs {
    &:before {
      background-image: url(../images/floor/icon-stairs.svg);
    }
	}
	/* .m-map--pictoicon_text-jr */
	&--pictoicon_text-jr {
    &:before {
      background-image: url(../images/floor/icon-jr.svg);
    }
	}
	/* .m-map--pictoicon_text-lounge */
	&--pictoicon_text-lounge {
    &:before {
      background-image: url(../images/floor/icon-lounge.svg);
    }
	}
	/* .m-map--pictoicon_text-locker */
	&--pictoicon_text-locker {
    &:before {
      background-image: url(../images/floor/icon-locker.svg);
    }
	}
	/* .m-map--pictoicon_text-smoking */
	&--pictoicon_text-smoking {
    &:before {
      background-image: url(../images/floor/icon-smoking.svg);
    }
	}
	/* .m-map--pictoicon_text-telphone */
	&--pictoicon_text-telphone {
    &:before {
      background-image: url(../images/floor/icon-telphone.svg);
    }
	}
	/* .m-map--pictoicon_text-register */
	&--pictoicon_text-register {
    &:before {
      background-image: url(../images/floor/icon-register.svg);
    }
	}
	/* .m-map--pictoicon_text-vending */
	&--pictoicon_text-vending {
    &:before {
      background-image: url(../images/floor/icon-vending.svg);
    }
	}
}

.m-shoplist {
	/* .m-shoplist--result_headline */
	&--result_headline {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    margin-bottom: 30px;
    @include sp {
      display: block;
      margin-bottom: 25px;
    }
	}
	/* .m-shoplist--result_text */
	&--result_text {
    font-size: 20px;
    margin-right: 30px;
    width: 200px;
    @include sp {
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
    }
	}
	/* .m-shoplist--result_number */
	&--result_number {
    font-size: 36px;
	}
	/* .m-shoplist--result_keylist */
	&--result_keylist {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    width: calc(100% - 230px);
    @include sp {
      width: 100%;
      flex-wrap: nowrap;
      overflow-y: scroll;
      padding: 0 15px;
      box-sizing: border-box;
    }
	}
	/* .m-shoplist--result_keyitem */
	&--result_keyitem {
    margin-right: 6px;
    &:last-of-type {
      margin-right: 0;
      position: relative;
      &:after {
        @include sp {  //iPhone対策
          content: "";
          position: absolute;
          top: 0;
          right: -15px;
          display: block;
          width: 15px;
          height: 100%;
        }
      }
    }
	}
	/* .m-shoplist--result_keylink */
	&--result_keylink {
    font-size: 13px;
    border: 1px solid #998675;
    border-radius: 3px;
    padding: 8px 7px;
    margin: 3px 0;
    white-space: nowrap;
    display: block;
    &:hover {
      @include hover-opacity;
    }
	}
  /* .m-shoplist--result_icon */
  &--result_icon {
    color: #b3b3b3;
    display: inline-block;
    padding-right: 8px;

  }
  /* .m-shoplist--shop_box */
	&--shop_box {
	}
	/* .m-shoplist--shop_list */
	&--shop_list {
    display: flex;
    flex-wrap: wrap;
	}
  &--attention {
    text-align: center;
    width: 100%;
    font-size: 19px;
    font-weight: bold;
  }
	/* .m-shoplist--shop_item */
	&--shop_item {
    width: 239px;
    margin-right: 48px;
    margin-top: 70px;
    @include sp {
      width: calc((100vw - 15px - 30px) / 2);
      margin-right: 15px;
      margin-top: 25px;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:nth-of-type(-n+4) {
      margin-top: 0;
    }
    &:nth-of-type(2n) {
      @include sp {
        margin-right: 0;
      }
    }
    &:nth-of-type(3),&:nth-of-type(4) {
      @include sp {
        margin-top: 25px;
      }
    }

	}
	/* .m-shoplist--shop_link */
	&--shop_link {
    display: block;
    &:hover {
      @include hover-opacity;
      .m-shoplist--shop_name {
        @include hover-underline;
      }
    }
	}
	/* .m-shoplist--shop_img_box */
	&--shop_img_box {
    border: 1px solid #ccc;
	}
	/* .m-shoplist--shop_photo_box */
	&--shop_photo_box {
    height: 237px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include sp {
      height: calc((100vw - 15px - 30px) / 2);
    }
	}
	/* .m-shoplist--shop_photo */
	&--shop_photo {
    object-fit: cover;
    width: 100%;
    height: 100%;
	}
	/* .m-shoplist--shop_logo_box */
	&--shop_logo_box {
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    @include sp {
      height: 95px;
    }
	}
	/* .m-shoplist--shop_logo */
	&--shop_logo {
    max-height: 100%;
    width: auto;
	}
	/* .m-shoplist--shop_text_box */
	&--shop_text_box {
    margin-top: 15px;
	}
	/* .m-shoplist--shop_name */
	&--shop_name {
    font-size: 16px;
    font-weight: bold;
	}
	/* .m-shoplist--shop_floor */
	&--shop_floor {
    color: $primary_color;
    font-size: 13px;
    margin-top: 10px;
    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 14px;
      background-image: url(../images/icon-map-red.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 5px;
      vertical-align: middle;
    }
	}
}


.m-shopphoto {
	/* .m-shopphoto--thumbnail */
	&--thumbnail {
    margin-top: 15px;
    @include sp {
      margin-top: 10px;
    }
	}
	/* .m-shopphoto--thumbnail_list */
	&--thumbnail_list {
    display: flex;
	}
	/* .m-shopphoto--thumbnail_item */
	&--thumbnail_item {
    width: 88px;
    height: 88px;
    margin-right: 15px;
    @include sp {
      margin-right: 10px;
      width: calc((100vw - 40px) / 5);
      height: calc((100vw - 40px) / 5);
    }
    &:last-of-type {
      margin-right: 0;
    }
	}
	/* .m-shopphoto--thumbnail_link */
	&--thumbnail_link {
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      @include hover-opacity;
    }
	}
  /* .m-shopphoto--img-s */
  &--img-s {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}


.m-favorite {
	/* .m-favorite--icon */
	&--icon {
    width: 19px;
    height: 16px;
    display: block;
    background-image: url(../images/icon-favorite.svg);
    background-repeat: no-repeat;
    background-size: 19px auto;
    background-position: 50% 50%;
    padding: 5px;
    &.active {
      background-image: url(../images/icon-favorite-active.svg);

    }
	}
}

.m-menu {
	/* .m-menu--category_list */
	&--category_list {
    display: flex;
    width: 960px;
    flex-wrap: wrap;
    margin: 0 auto 50px;
    @include sp {
      overflow: scroll;
      width: 100%;
      justify-content: flex-start;
      padding: 0 15px;
      box-sizing: border-box;
      margin-bottom: 20px;
      flex-wrap: nowrap;
    }
	}
	/* .m-menu--category_item */
	&--category_item {
    margin-right: 5px;
    margin-bottom: 5px;
    &:nth-of-type(5n) {
      margin-right: 0;
      @include sp {
        margin-right: 5px;
      }
    }
    &:last-of-type {
      margin-right: 0;
      position: relative;
      &:after {
        @include sp {  //iPhone対策
          content: "";
          position: absolute;
          top: 0;
          right: -15px;
          display: block;
          width: 15px;
          height: 100%;
        }
      }
    }
    &.active,
    &.current {
      .m-menu--category_link {
        color: #fff;
        background-color: $primary_color;
      }
    }
    &:not(.active) {
      .m-menu--category_link {
        &:focus {
          background-color: #fff;
          color: #333;
        }
      }
    }
	}
	/* .m-menu--category_link */
	&--category_link {
    display: block;
    background-color: #fff;
    min-width: 166px;
    border: 1px solid #ccc;
    font-weight: 500;
    text-align: center;
    padding: 20px 10px;
    word-break: break-all;
    @include sp {
      white-space: nowrap;
    }
    &:hover {
      @include hover-bgcolor;
    }
	}
}

.m-newshop {
  /* .m-newshop--box */
  &--box {
    width: 980px;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
  }

	/* .m-newshop--headline */
	&--headline {
    background-color: $gray;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 50px;
	}
	/* .m-newshop--shop_list */
	&--shop_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 25px;
	}
	/* .m-newshop--shop_item */
	&--shop_item {
    width: 50%;
    position: relative;
    margin-top: 25px;
    padding-top: 25px;
    @include sp {
      width: 100%;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 22px);
      height: 2px;
      background-color: #ccc;
      display: block;
      @include sp {
        width: 100%;
      }
    }
    &:nth-of-type(-n+2) {
      margin-top: 0;
      padding-top: 0;
      &:before {
        content: none;
      }
    }
    &:nth-of-type(2n) {
      &:before {
        left: auto;
        right: 0;
      }
      .m-newshop--article_box {
        padding-left: 22px;
        padding-right: 0;
        border-left: 2px solid #ccc;
        @include sp {
          padding-left: 0;
          padding-right: 22px;
          border-left: none;
        }
      }
    }
    &:nth-of-type(2) {
      @include sp {
        padding-top: 25px;
        margin-top: 25px;
      }
      &:before {
        @include sp {
          content: "";
        }
      }
    }
	}
	/* .m-newshop--article_box */
	&--article_box {
    padding-right: 22px;
    display: block;
    &:hover {
      .m-newshop--article_title {
        @include hover-underline;
      }
    }
	}
	/* .m-newshop--article_link */
	&--article_link {
    display: flex;
	}
	/* .m-newshop--article_img_box */
	&--article_img_box {
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    transition: .3s all;
    box-sizing: border-box;
    @include sp {
      width: 80px;
      height: 80px;
    }
	}
	/* .m-newshop--article_img */
	&--article_img {
	}
	/* .m-newshop--article_text */
	&--article_text {
    width: calc(100% - 160px - 30px);
    @include sp {
      width: calc(100% - 80px - 30px);
    }
	}
	/* .m-newshop--article_date */
	&--article_date {
    color: #999;
    font-size: 12px;
	}
  /* .m-newshop--article_title */
  &--article_title {
    font-weight: bold;
    font-size: 16px;
    margin-top: 8px;
    padding-right: 5px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    /*IE対策*/
    line-height: 1.5em;
    max-height: 4.5em;
  }
	/* .m-newshop--article_shopname */
	&--article_shopname {
    color: $primary_color;
    font-size: 13px;
    margin-top: 8px;
	}
	/* .m-newshop--article_floor */
	&--article_floor {
    color: $primary_color;
    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 14px;
      background-image: url(../images/icon-map-red.svg);
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle;
    }
	}
}
